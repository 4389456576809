import EventBus from '@/services/event-bus/EventBus';

/**
 * Mixins for shared methods for CopyboxLatest and Copybox Reference.
 * These mixins are strictly used for Copybox.
 *
 * @see {@link https://vuejs.org/v2/guide/mixins.html|Vue Mixins}
 */
export default {
  data() {
    return {
      showComparison: false,
      listing: null,
      source: null,
    };
  },
  mounted() {
    EventBus.$on('show-content-comparison', this.onShowContentComparison);
  },
  beforeDestroy() {
    EventBus.$off('show-content-comparison');
  },
  methods: {
    /**
     * Method to handle editing copybox column headers.
     *
     * @param {Object} header Header value object of new and old value
     */
    onUpdateHeaderText(header) {
      /**
       * @todo Currently we have no uniform service name for updating
       * exportSettings and columnPreferences, hence one uses
       * "copybox" and the other uses "Copybox"
       * In the future we'll need to just make one consistent
       * name so we don't get confused. For simplicity, just
       * keep everything camelCase.
       */
      const exportSettingsService = 'copybox';
      const columnPreferencesService = 'Copybox';

      const {
        agGridParams,
        oldValue,
        newValue,
      } = header;
      const { column } = agGridParams;
      let { field } = column.colDef;

      // Append _custom for any custom column fields
      // This is how we differentiate custom fields
      // from hard-coded ones
      if (column.parent.groupId === 'custom') {
        field += '_custom';
        // Handle if the field is not custom
        // and has to have a diff header key name
      } else if (field === 'listing') {
        field = 'id';
      } else if (field === 'compliance') {
        field = 'compliant';
      } else if (field === 'compproblem') {
        field = 'complianceissues';
      } else if (field === 'guideline') {
        field = 'template';
      } else if (field === 'imgurl') {
        field = 'image';
      } else if (field === 'num_images') {
        field = 'numimages';
      } else if (field === 'dtype') {
        field = 'type';
      } else if (field === 'num_variations') {
        field = 'numvariations';
      } else if (field === 'num_categories') {
        field = 'numcategories';
      } else if (field === 'ts') {
        field = 'updated';
      }

      this.updateColumnHeaderName(
        exportSettingsService,
        columnPreferencesService,
        field,
        newValue,
        oldValue,
        agGridParams,
      );

      /**
       * Update the column header name and refresh the grid headers
       * so that the value is rendered correctly.
       */
      if (column.gridApi) {
        column.colDef.headerName = newValue;
        column.colDef.lockPosition = false;
        column.gridApi.refreshHeader();
      }
    },
    onShowContentComparison(payload) {
      const { listing, source } = payload;
      this.listing = listing;
      this.source = source;
      this.showComparison = true;
    },
    handleCloseComparison() {
      this.showComparison = false;
    },
  },
};
