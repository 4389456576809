var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row justify-content-center" }, [
    _vm.isLoading
      ? _c(
          "div",
          { staticClass: "col-12" },
          [_c("loading-square", { attrs: { message: "Loading..." } })],
          1
        )
      : _vm._e(),
    !_vm.isLoading
      ? _c(
          "div",
          { staticClass: "col-10 mx-auto" },
          [
            _c("el-page-header", {
              staticClass: "mb-3",
              attrs: { title: "Reports", content: _vm.reportTitle },
              on: { back: () => _vm.$router.push("/reports") },
            }),
            _vm._l(_vm.reportFrames, function (frame, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "mb-3 p-3 rounded shadow-sm border-1 bg-white",
                  staticStyle: { "min-width": "830px" },
                },
                [
                  frame.type === "chart"
                    ? _c("chart-frame", {
                        attrs: {
                          data: frame.data,
                          layout: frame.layout,
                          configure: frame.configure,
                        },
                      })
                    : frame.type === "grid"
                    ? _c("grid-frame", {
                        attrs: {
                          "grid-title": frame.grid_title,
                          "row-data": frame.row_data,
                          "column-defs": frame.column_defs,
                        },
                      })
                    : frame.type === "text"
                    ? _c("text-frame", { attrs: { content: frame.value } })
                    : _vm._e(),
                ],
                1
              )
            }),
            _vm.reportFrames.length < 1
              ? _c(
                  "div",
                  { staticClass: "text-center my-5" },
                  [
                    _c("chart-box-icon", { staticClass: "rb-icon rb-icon-4x" }),
                    _c("h4", [_vm._v(" Cannot View Report ")]),
                    _c("p", [_vm._v(" Please try again later. ")]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }