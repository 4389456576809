var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-12 mb-2" },
        [
          _c("collapse-header", {
            attrs: { loading: _vm.isLoading },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [
                    _vm._v(" Reports and Exports "),
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Click to get a walkthrough of this page",
                          "open-delay": 500,
                        },
                      },
                      [
                        _vm.reportsExportsList.length
                          ? _c("sup", [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "rb-help-btn btn btn-link btn-sm",
                                  on: {
                                    click:
                                      _vm.onActiveReportsAndExportsWalkthrough,
                                  },
                                },
                                [
                                  _c("info-icon", {
                                    staticClass: "rb-icon",
                                    attrs: { title: "" },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "help",
                fn: function () {
                  return [
                    !_vm.isLoading
                      ? _c("active-reports-and-exports-help-button")
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      !_vm.isLoading
        ? _c(
            "div",
            {
              staticClass:
                "col-12 d-flex flex-column flex-lg-row flex-wrap align-items-lg-end rb-select-report-filter-toolbar mb-3",
            },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-column flex-lg-row" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column mr-xl-2 mb-2 mb-lg-0 rb-report-search-input",
                    },
                    [
                      _c("label", { attrs: { for: "search" } }, [
                        _vm._v(" Search "),
                      ]),
                      _c(
                        "el-input",
                        {
                          attrs: {
                            id: "search",
                            placeholder: "Find a report or export",
                            name: "search",
                            size: "small",
                            disabled: _vm.reportsExportsList.length < 1,
                          },
                          on: { input: _vm.onSearchInput },
                          model: {
                            value: _vm.searchWord,
                            callback: function ($$v) {
                              _vm.searchWord = $$v
                            },
                            expression: "searchWord",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex align-items-center",
                              attrs: { slot: "prepend" },
                              slot: "prepend",
                            },
                            [
                              _c("search-icon", {
                                staticClass: "rb-icon-lg text-secondary",
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column rb-report-types-input mr-xl-2 mb-2 mb-lg-0",
                    },
                    [
                      _c("label", { attrs: { for: "types" } }, [
                        _vm._v(" Type "),
                      ]),
                      _c(
                        "el-select",
                        {
                          staticClass: "rb-select el-select--light",
                          attrs: {
                            id: "types",
                            filterable: "",
                            name: "types",
                            placeholder: "Select service",
                            size: "small",
                            disabled: _vm.reportsExportsList.length < 1,
                          },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(_vm.types, function (type) {
                          return _c("el-option", {
                            key: type.label,
                            staticClass: "text-capitalize",
                            attrs: { label: type.label, value: type.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isFiltersActive
                    ? _c(
                        "el-button",
                        {
                          staticClass: "ml-2 align-self-end text-info",
                          attrs: { type: "text" },
                          on: { click: _vm.onClearFilters },
                        },
                        [_vm._v(" Clear filters ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "ml-lg-auto my-2 my-lg-0 rb-create-button-group",
                },
                [
                  _c(
                    "el-tooltip",
                    {
                      attrs: { "open-delay": 500, placement: "top" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "content",
                            fn: function () {
                              return [
                                _c("p", [
                                  _vm._v("A report is a "),
                                  _c("strong", [_vm._v("customizable")]),
                                  _vm._v(
                                    " summary of data, including graphs and metrics."
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2962081540
                      ),
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "el-button el-button--success el-button--small",
                          attrs: { to: "/reports/create?type=reports" },
                        },
                        [_vm._v(" Create report ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tooltip",
                    {
                      attrs: { "open-delay": 500, placement: "top" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "content",
                            fn: function () {
                              return [
                                _c("p", [
                                  _vm._v(
                                    "An export contains the raw data behind the reports and is available for download as a "
                                  ),
                                  _c("strong", [_vm._v("CSV")]),
                                  _vm._v(" file."),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        362390816
                      ),
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass:
                            "el-button el-button--default el-button--small",
                          attrs: { to: "/reports/create?type=exports" },
                        },
                        [_vm._v(" Create export ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _c("div", { staticClass: "col-12 d-flex mb-3" }, [
            _vm._m(0),
            _vm._m(1),
          ]),
      !_vm.isLoading
        ? _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _vm._l(_vm.filteredReportsExportsList, function (report) {
                  return _c(
                    "div",
                    {
                      key: report.id,
                      staticClass: "col-12 col-lg-6 col-xl-4 mb-3",
                    },
                    [
                      _c("report-card", {
                        attrs: { report: report },
                        on: {
                          "delete-report": _vm.onDeleteReport,
                          "view-report": _vm.onViewReport,
                          "edit-report": _vm.onEditReport,
                          "download-report": _vm.onDownloadReport,
                          "share-report": _vm.onShareReport,
                        },
                      }),
                    ],
                    1
                  )
                }),
                _vm.filteredReportsExportsList.length === 0 &&
                _vm.reportsExportsList.length
                  ? _c(
                      "div",
                      { staticClass: "col-5 mx-auto text-center my-5" },
                      [
                        _c("file-search-outline-icon", {
                          staticClass: "rb-icon rb-icon-3x",
                        }),
                        _c("h5", [_vm._v(" No Results ")]),
                        _c("p", [
                          _vm._v(
                            " No reports or exports found. Please try updating your filters. "
                          ),
                        ]),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success" },
                            on: { click: _vm.onClearFilters },
                          },
                          [_vm._v(" Clear filters ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.reportsExportsList && _vm.reportsExportsList.length === 0
                  ? _c(
                      "div",
                      { staticClass: "col-12 col-md-8 mx-auto my-5" },
                      [
                        _c(
                          "empty-state",
                          {
                            attrs: {
                              title: "Let's Get Started",
                              subtitle:
                                "You do not have any reports or exports",
                              image: _vm.emptyImage,
                            },
                          },
                          [
                            _c("div", { staticClass: "mb-4" }, [
                              _c("p", { staticClass: "card-text mb-1" }, [
                                _vm._v(
                                  " Create a customizable summary of your data, including graphs and pre-calculated metrics. "
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                attrs: { slot: "card-actions" },
                                slot: "card-actions",
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "el-button el-button--success",
                                    attrs: {
                                      to: "/reports/create?type=reports",
                                    },
                                  },
                                  [_vm._v(" Create report ")]
                                ),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "el-button el-button--default",
                                    attrs: {
                                      to: "/reports/create?type=exports",
                                    },
                                  },
                                  [_vm._v(" Create export ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "text-center mt-3",
                                attrs: { slot: "helper-text" },
                                slot: "helper-text",
                              },
                              [
                                _vm._v(" Need additional help? "),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "https://helpcenter.getreviewbox.com/data-exports-and-reports",
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Learn more about reports and exports."
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
          ])
        : _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "row" },
              _vm._l(12, function (n) {
                return _c(
                  "div",
                  { key: n, staticClass: "col-12 col-lg-6 col-xl-4 p-3" },
                  [
                    _c("div", {
                      staticClass: "skeleton skeleton-block rounded",
                    }),
                  ]
                )
              }),
              0
            ),
          ]),
      _c("share-report-modal", {
        attrs: { show: _vm.shareReport.show, report: _vm.shareReport },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.shareReport, "show", $event)
          },
          "on-cancel": _vm.onCancelShareReportModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex" }, [
      _c("div", { staticClass: "skeleton skeleton-button mr-2" }),
      _c("div", { staticClass: "skeleton skeleton-button" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ml-auto" }, [
      _c("div", { staticClass: "skeleton skeleton-button" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }