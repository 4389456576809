var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("base-grid", {
        ref: _vm.gridRef,
        attrs: {
          service: _vm.service,
          "edit-header": true,
          "is-grid-loading": _vm.isGridLoading(_vm.service),
          "grid-state": _vm.gridState(_vm.service),
          "grid-filter": _vm.filterState(_vm.service),
          "download-options": _vm.downloadOptions(_vm.service),
          "enable-filter-actions": true,
          "enable-delete-rows": true,
          "enable-custom-column-edit": true,
          "suppress-row-click-selection": true,
          "enable-row-selection": true,
          "enable-select-all": true,
          "row-selection": "multiple",
          "column-cell-renderers": _vm.columnCellRenderers(_vm.service),
          "column-cell-editors": _vm.columnCellEditors(_vm.service),
        },
        on: {
          "set-grid-state": (payload) =>
            _vm.setGridState({ service: _vm.service, ...payload }),
          "select-download": _vm.onSelectDownload,
          "cell-value-changed": _vm.onCellValueChanged,
          "column-changed": (obj) => _vm.onColumnChanged("Copybox", obj),
          "save-grid-filters": _vm.onSaveGridFilters,
          "delete-grid-filters": _vm.onDeleteGridFilters,
          "update-header-text": _vm.onUpdateHeaderText,
          "filter-changed": _vm.onFiltersChanged,
          "filter-cleared": _vm.onFiltersCleared,
          "row-selected": _vm.onRowSelection,
        },
        scopedSlots: _vm._u([
          {
            key: "grid-toolbar",
            fn: function () {
              return [
                _vm.selectedReferenceContent &&
                _vm.selectedReferenceContent.length > 0
                  ? _c(
                      "el-tooltip",
                      {
                        attrs: {
                          content: "Delete selected reference content",
                          effect: "dark",
                          placement: "top",
                          "open-delay": 500,
                        },
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _c("p", [
                              _vm._v(
                                " Delete reference content for the selected " +
                                  _vm._s(
                                    _vm.isSelectedPlural
                                      ? "listings"
                                      : "listing"
                                  ) +
                                  ". "
                              ),
                            ]),
                            _c("p", [
                              _c("strong", [_vm._v("Note:")]),
                              _vm._v(
                                " This will only delete the reference content and not the listing itself. "
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              icon: "el-icon-delete",
                              size: "small",
                            },
                            on: { click: _vm.onDeleteReferenceContent },
                          },
                          [
                            _vm._v(
                              " Delete " +
                                _vm._s(_vm.selectedReferenceContent.length) +
                                " " +
                                _vm._s(
                                  _vm.isSelectedPlural
                                    ? "references"
                                    : "reference"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("save-filters-modal", {
        attrs: {
          show: _vm.showSaveFiltersModal.show,
          "is-save-filter": _vm.showSaveFiltersModal.isSaveFilter,
          filters: _vm.showSaveFiltersModal.filters,
          "grid-filter": _vm.showSaveFiltersModal.gridFilter,
          "grid-sort": _vm.showSaveFiltersModal.gridSort,
          "is-loading": _vm.isGridFilterLoading,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.showSaveFiltersModal, "show", $event)
          },
          onConfirmSaveGridFilter: (obj) =>
            _vm.onConfirmSaveGridFilter("Copybox", obj),
          onConfirmDeleteGridFilter: (obj) =>
            _vm.onConfirmDeleteGridFilter("Copybox", obj),
          onCancelSaveFilters: _vm.onCancelSaveFilters,
        },
      }),
      _c("grid-download-modal", {
        attrs: {
          show: _vm.gridDownloadModal.show,
          export: _vm.gridDownloadModal.export,
        },
        on: {
          close: _vm.onResetGridDownloadModal,
          submit: _vm.onSubmitGridDownloadModal,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Content Comparison",
            "custom-class": "dialog-secondary el-dialog--sticky",
            visible: _vm.showComparison,
            "before-close": _vm.handleCloseComparison,
            fullscreen: false,
            "destroy-on-close": true,
            width: "95%",
            top: "60px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showComparison = $event
            },
          },
        },
        [
          _vm.showComparison
            ? _c("content-comparison", {
                attrs: { listing: _vm.listing, source: _vm.source },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }