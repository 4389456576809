var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "vh-100" },
            [_c("loading-square", { attrs: { message: "Loading..." } })],
            1
          )
        : _vm._e(),
      _vm.formErrorMessage
        ? _c(
            "el-alert",
            { staticClass: "mb-3", attrs: { type: "error", closable: false } },
            [_vm._v(" " + _vm._s(_vm.formErrorMessage) + " ")]
          )
        : _vm._e(),
      _vm.isContent
        ? _c(
            "div",
            [
              _vm.failedGuidelines.length > 0
                ? _c(
                    "el-alert",
                    {
                      staticClass: "mb-3",
                      attrs: { type: "warning", closable: false },
                    },
                    [
                      _c("p", [
                        _vm._v(" The following guidelines have failed: "),
                      ]),
                      _c(
                        "ul",
                        _vm._l(
                          _vm.failedGuidelines,
                          function (guideline, index) {
                            return _c("li", { key: index }, [
                              _vm._v(" " + _vm._s(guideline) + " "),
                            ])
                          }
                        ),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "d-flex mb-2" },
                [
                  _vm.parent
                    ? _c("div", { staticClass: "mr-2" }, [
                        _c("label", { staticClass: "font-weight-bold" }, [
                          _vm._v(" Parent ASIN: "),
                        ]),
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.parent) + " "),
                          _vm.parenturl
                            ? _c("span", [
                                _vm._v(" ("),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.parenturl,
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v("link")]
                                ),
                                _vm._v(") "),
                              ])
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.listingId
                    ? _c("div", { staticClass: "mr-2" }, [
                        _c("label", { staticClass: "font-weight-bold" }, [
                          _vm._v(" ASIN: "),
                        ]),
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.listingId) + " "),
                          _vm.url
                            ? _c("span", [
                                _vm._v(" ("),
                                _c(
                                  "a",
                                  {
                                    attrs: { href: _vm.url, target: "_blank" },
                                  },
                                  [_vm._v("link")]
                                ),
                                _vm._v(") "),
                              ])
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.sourceName
                    ? _c("div", [
                        _c("label", { staticClass: "font-weight-bold" }, [
                          _vm._v(" Source: "),
                        ]),
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.sourceName) + " "),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.hasDifferences
                    ? _c(
                        "el-tooltip",
                        {
                          staticClass: "ml-auto",
                          attrs: { placement: "top", "open-delay": 500 },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.showDifferences
                                      ? "Show all content"
                                      : "Filter to view only content differences"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "success",
                                size: "mini",
                                plain: "",
                              },
                              on: { click: _vm.onToggleDifferences },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.showDifferences
                                      ? "Show All"
                                      : "Show differences"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "table",
                { staticClass: "table table-bordered content-table" },
                [
                  _c("tr", [
                    _c("th", { staticClass: "content-table-headers" }),
                    _c("th", { staticClass: "browse-subtitle" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("span", [
                            _vm._v(" " + _vm._s(_vm.baselineGridTitle) + " "),
                          ]),
                          _vm.canonical && !_vm.update
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "ml-auto",
                                  attrs: {
                                    size: "small",
                                    type: "info",
                                    loading: _vm.isUpdating,
                                    plain: "",
                                  },
                                  on: { click: _vm.updateCanonical },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "d-flex align-items-center",
                                    },
                                    [
                                      _c("pencil-icon", {
                                        attrs: { title: "" },
                                      }),
                                      _c("span", { staticClass: "ml-1" }, [
                                        _vm._v("Update"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.update
                            ? _c(
                                "div",
                                { staticClass: "ml-auto" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "small",
                                        loading: _vm.isUpdating,
                                        plain: "",
                                      },
                                      on: {
                                        click: () => (_vm.update = !_vm.update),
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "small",
                                        type: "success",
                                        loading: _vm.isUpdating,
                                        plain: "",
                                      },
                                      on: { click: _vm.postCanonicalUpdate },
                                    },
                                    [_vm._v(" Save ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _c("th", { staticClass: "browse-subtitle" }, [
                      _vm._v(" " + _vm._s(_vm.comparisonGridTitle) + " "),
                    ]),
                  ]),
                  !_vm.showDifferences ||
                  (_vm.showDifferences && _vm.gridclass.name === "table-danger")
                    ? _c("tr", [
                        _c("th", { staticClass: "browse-subtitle" }, [
                          _vm._v(" Name "),
                        ]),
                        !_vm.update
                          ? _c("td", {
                              domProps: {
                                innerHTML: _vm._s(_vm.baselineContent.name),
                              },
                            })
                          : _vm._e(),
                        _vm.update
                          ? _c("td", [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.canonicalUpdate.name,
                                    expression: "canonicalUpdate.name",
                                  },
                                ],
                                staticClass: "textareasize",
                                attrs: {
                                  id: "canonicalUpdateName",
                                  autocomplete: "off",
                                },
                                domProps: { value: _vm.canonicalUpdate.name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.canonicalUpdate,
                                      "name",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                        _c("td", {
                          class: _vm.gridclass.name,
                          domProps: {
                            innerHTML: _vm._s(_vm.comparisonContent.name),
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.showDifferences ||
                  (_vm.showDifferences &&
                    _vm.gridclass.brand === "table-danger")
                    ? _c("tr", [
                        _c("th", { staticClass: "browse-subtitle" }, [
                          _vm._v(" Brand "),
                        ]),
                        !_vm.update
                          ? _c("td", {
                              domProps: {
                                innerHTML: _vm._s(_vm.baselineContent.brand),
                              },
                            })
                          : _vm._e(),
                        _vm.update
                          ? _c("td", [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.canonicalUpdate.brand,
                                    expression: "canonicalUpdate.brand",
                                  },
                                ],
                                staticClass: "textareasize",
                                attrs: {
                                  id: "canonicalUpdateBrand",
                                  autocomplete: "off",
                                },
                                domProps: { value: _vm.canonicalUpdate.brand },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.canonicalUpdate,
                                      "brand",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                        _c("td", {
                          class: _vm.gridclass.brand,
                          domProps: {
                            innerHTML: _vm._s(_vm.comparisonContent.brand),
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.showDifferences ||
                  (_vm.showDifferences &&
                    _vm.gridclass.categories === "table-danger")
                    ? _c("tr", [
                        _c("th", { staticClass: "browse-subtitle" }, [
                          _vm._v(" Categories "),
                        ]),
                        !_vm.update
                          ? _c("td", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.baselineContent.categories
                                ),
                              },
                            })
                          : _vm._e(),
                        _vm.update
                          ? _c("td", [
                              _vm._v(
                                " Reference categories are not supported at this time. "
                              ),
                            ])
                          : _vm._e(),
                        _c("td", {
                          class: _vm.gridclass.categories,
                          domProps: {
                            innerHTML: _vm._s(_vm.comparisonContent.categories),
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.showDifferences ||
                  (_vm.showDifferences &&
                    _vm.gridclass.variations === "table-danger")
                    ? _c("tr", [
                        _c("th", { staticClass: "browse-subtitle" }, [
                          _vm._v(" Variations "),
                        ]),
                        !_vm.update
                          ? _c("td", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.baselineContent.variations
                                ),
                              },
                            })
                          : _vm._e(),
                        _vm.update
                          ? _c("td", [
                              _vm._v(
                                " Reference variations are not supported at this time. "
                              ),
                            ])
                          : _vm._e(),
                        _c("td", {
                          class: _vm.gridclass.variations,
                          domProps: {
                            innerHTML: _vm._s(_vm.comparisonContent.variations),
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.showDifferences ||
                  (_vm.showDifferences &&
                    _vm.gridclass.features === "table-danger")
                    ? _c("tr", [
                        _c("th", { staticClass: "browse-subtitle" }, [
                          _vm._v(" Features "),
                        ]),
                        !_vm.update
                          ? _c("td", {
                              domProps: {
                                innerHTML: _vm._s(_vm.baselineContent.features),
                              },
                            })
                          : _vm._e(),
                        _vm.update
                          ? _c("td", [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.canonicalUpdate.features,
                                    expression: "canonicalUpdate.features",
                                  },
                                ],
                                staticClass: "textareasize",
                                attrs: {
                                  id: "canonicalUpdateFeatures",
                                  autocomplete: "off",
                                },
                                domProps: {
                                  value: _vm.canonicalUpdate.features,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.canonicalUpdate,
                                      "features",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                        _c("td", {
                          class: _vm.gridclass.features,
                          domProps: {
                            innerHTML: _vm._s(_vm.comparisonContent.features),
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.showDifferences ||
                  (_vm.showDifferences &&
                    _vm.gridclass.description === "table-danger")
                    ? _c("tr", [
                        _c("th", { staticClass: "browse-subtitle" }, [
                          _vm._v(" Description "),
                        ]),
                        !_vm.update
                          ? _c("td", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.baselineContent.description
                                ),
                              },
                            })
                          : _vm._e(),
                        _vm.update
                          ? _c("td", [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.canonicalUpdate.description,
                                    expression: "canonicalUpdate.description",
                                  },
                                ],
                                staticClass: "textareasize",
                                attrs: {
                                  id: "canonicalUpdateDescription",
                                  autocomplete: "off",
                                },
                                domProps: {
                                  value: _vm.canonicalUpdate.description,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.canonicalUpdate,
                                      "description",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                        _c("td", {
                          class: _vm.gridclass.description,
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.comparisonContent.description
                            ),
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.showDifferences ||
                  (_vm.showDifferences &&
                    _vm.gridclass.dtype === "table-danger")
                    ? _c("tr", [
                        _c("th", { staticClass: "browse-subtitle" }, [
                          _vm._v(" Description Type "),
                        ]),
                        !_vm.update
                          ? _c("td", {
                              domProps: {
                                innerHTML: _vm._s(_vm.baselineContent.dtype),
                              },
                            })
                          : _vm._e(),
                        _vm.update
                          ? _c("td", [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.canonicalUpdate.dtype,
                                    expression: "canonicalUpdate.dtype",
                                  },
                                ],
                                staticClass: "textareasize",
                                attrs: {
                                  id: "canonicalUpdateDtype",
                                  autocomplete: "off",
                                },
                                domProps: { value: _vm.canonicalUpdate.dtype },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.canonicalUpdate,
                                      "dtype",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                        _c("td", {
                          class: _vm.gridclass.dtype,
                          domProps: {
                            innerHTML: _vm._s(_vm.comparisonContent.dtype),
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.showDifferences ||
                  (_vm.showDifferences &&
                    _vm.gridclass.details === "table-danger")
                    ? _c("tr", [
                        _c("th", { staticClass: "browse-subtitle" }, [
                          _vm._v(" Details "),
                        ]),
                        !_vm.update
                          ? _c("td", {
                              domProps: {
                                innerHTML: _vm._s(_vm.baselineContent.details),
                              },
                            })
                          : _vm._e(),
                        _vm.update
                          ? _c("td", [
                              _vm._v(
                                " Reference details are not supported at this time. "
                              ),
                            ])
                          : _vm._e(),
                        _c("td", {
                          class: _vm.gridclass.details,
                          domProps: {
                            innerHTML: _vm._s(_vm.comparisonContent.details),
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.showDifferences ||
                  (_vm.showDifferences &&
                    _vm.gridclass.badges === "table-danger")
                    ? _c("tr", [
                        _c("th", { staticClass: "browse-subtitle" }, [
                          _vm._v(" Badges "),
                        ]),
                        !_vm.update
                          ? _c("td", {
                              domProps: {
                                innerHTML: _vm._s(_vm.baselineContent.badges),
                              },
                            })
                          : _vm._e(),
                        _vm.update
                          ? _c("td", [
                              _vm._v(
                                " Reference badges are not supported at this time. "
                              ),
                            ])
                          : _vm._e(),
                        _c("td", {
                          class: _vm.gridclass.badges,
                          domProps: {
                            innerHTML: _vm._s(_vm.comparisonContent.badges),
                          },
                        }),
                      ])
                    : _vm._e(),
                  !_vm.showDifferences ||
                  (_vm.showDifferences &&
                    _vm.gridclass.images === "table-danger")
                    ? _c("tr", [
                        _c("th", { staticClass: "browse-subtitle" }, [
                          _vm._v(" Images "),
                        ]),
                        !_vm.update
                          ? _c("td", {
                              domProps: {
                                innerHTML: _vm._s(_vm.baselineContent.images),
                              },
                            })
                          : _vm._e(),
                        _vm.update
                          ? _c("td", [
                              _vm._v(
                                " Reference images are not supported at this time. "
                              ),
                            ])
                          : _vm._e(),
                        _c("td", {
                          class: _vm.gridclass.images,
                          domProps: {
                            innerHTML: _vm._s(_vm.comparisonContent.images),
                          },
                        }),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading && !_vm.isContent
        ? _c("div", { staticClass: "text-center text-uppercase" }, [
            _c(
              "h1",
              { staticClass: "text-danger mb-0" },
              [_c("alert-circle-icon")],
              1
            ),
            _c("h3", { staticClass: "text-danger" }, [
              _vm._v(" Failed to retrieve product information "),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }