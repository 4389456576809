var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("card", { staticClass: "h-100 w-100 rb-report-card" }, [
    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between" },
        [
          _c(
            "h5",
            { staticClass: "text-wrap w-75" },
            [
              _c("truncate-text", {
                attrs: { text: _vm.reportTitle, limit: 100 },
              }),
            ],
            1
          ),
          _c(
            "el-dropdown",
            {
              staticClass: "rb-share-delete-button-group",
              attrs: { trigger: "click" },
              on: { command: _vm.onHandleDropdownAction },
            },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "More Actions",
                    placement: "top",
                    "open-delay": 500,
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "rb-actions-dropdown el-button el-button--mini p-1 px-2 d-flex align-items-center",
                    },
                    [
                      _c("dots-vertical-icon", {
                        staticClass: "rb-icon",
                        attrs: { title: "" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _vm.report.is_viewable
                    ? _c(
                        "el-dropdown-item",
                        {
                          attrs: { icon: "el-icon-share", command: "share" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit(
                                "share-report",
                                _vm.report.id,
                                _vm.report.title
                              )
                            },
                          },
                        },
                        [_vm._v(" Share " + _vm._s(_vm.reportType) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: {
                        divided: !!_vm.report.is_viewable,
                        icon: "el-icon-delete-solid",
                        command: "delete",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("delete-report", _vm.report)
                        },
                      },
                    },
                    [_vm._v(" Delete " + _vm._s(_vm.reportType) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("h6", { staticClass: "text-muted" }, [
            _vm._v(" Type: " + _vm._s(_vm.report.type) + " "),
          ]),
          _vm._l(_vm.tags, function (tag, index) {
            return _c(
              "el-tooltip",
              {
                key: index,
                attrs: {
                  disabled: !tag.tooltip,
                  content: tag.tooltip,
                  "open-delay": 500,
                },
              },
              [
                _c(
                  "el-tag",
                  {
                    staticClass: "mr-1 mb-1 d-inline-flex align-items-center",
                    attrs: { type: tag.type, size: "small" },
                  },
                  [
                    _c(tag.icon, {
                      tag: "component",
                      staticClass: "rb-icon rb-icon-xs mr-1",
                    }),
                    _vm._v(" " + _vm._s(tag.label) + " "),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ]),
    _c("div", { staticClass: "card-text" }, [
      _vm.report.info
        ? _c(
            "div",
            { staticClass: "mb-1" },
            [
              _c("strong", [_vm._v("Description: ")]),
              _c("truncate-text", {
                attrs: { text: _vm.report.info, limit: 150, "read-more": true },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.report.title
        ? _c(
            "div",
            { staticClass: "mb-1" },
            [
              _c("strong", [_vm._v("Filename: ")]),
              _c("truncate-text", {
                attrs: { text: _vm.report.title, limit: 100 },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.formattedDateRange
        ? _c(
            "div",
            { staticClass: "mb-1" },
            [
              _c("strong", [_vm._v("Report Range: ")]),
              _c("truncate-text", {
                attrs: { text: _vm.formattedDateRange, limit: 100 },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.report.notes
        ? _c(
            "div",
            { staticClass: "mb-1" },
            [
              _c("strong", [_vm._v("Notes: ")]),
              _c("truncate-text", {
                attrs: { text: _vm.report.notes, limit: 100 },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      {
        staticClass: "pt-0 d-flex justify-content-between",
        attrs: { slot: "footer" },
        slot: "footer",
      },
      [
        _c(
          "div",
          { staticClass: "rb-view-edit-button-group" },
          [
            _vm.report.is_viewable
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      size: "small",
                      type: "success",
                      disabled: _vm.report.is_viewable !== "success",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit(
                          "view-report",
                          _vm.report.id,
                          _vm.reportTitle
                        )
                      },
                    },
                  },
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          disabled: _vm.report.is_viewable === "success",
                          content: _vm.report.is_viewable,
                          "open-delay": 300,
                          placement: "top",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function () {
                                return [
                                  _vm.report.is_viewable == "pending"
                                    ? _c("span", [
                                        _vm._v(
                                          " Waiting for report to finish "
                                        ),
                                      ])
                                    : _vm.report.is_viewable == "failed"
                                    ? _c("span", [
                                        _vm._v(
                                          " Unable to view report. Please try again later. "
                                        ),
                                      ])
                                    : _c("span", [_vm._v(" View report ")]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2394662256
                        ),
                      },
                      [_c("span", [_vm._v(" View ")])]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.report.is_editable
              ? _c(
                  "el-tooltip",
                  { attrs: { "open-delay": 300, placement: "top" } },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _vm._v(
                        " Edit your " + _vm._s(_vm.reportType) + " settings "
                      ),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "default" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("edit-report", _vm.report.id)
                          },
                        },
                      },
                      [_vm._v(" Edit ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "el-dropdown",
          {
            staticClass: "ml-auto rb-download-button-group",
            attrs: { trigger: "click" },
            on: { command: _vm.onHandleDropdownAction },
          },
          [
            _c(
              "span",
              { staticClass: "el-button el-button--default el-button--small" },
              [
                _vm._v(" Download "),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ]
            ),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              _vm._l(
                _vm.report.exportFileOptions,
                function (exportFileType, fileIndex) {
                  return _c(
                    "el-dropdown-item",
                    {
                      key: fileIndex,
                      attrs: {
                        icon: "el-icon-download",
                        command: `download-${exportFileType}`,
                      },
                    },
                    [_vm._v(" Download " + _vm._s(exportFileType) + " ")]
                  )
                }
              ),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }