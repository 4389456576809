var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      {
        staticClass: "col-12 col-xl-8",
        staticStyle: { "max-width": "1200px" },
      },
      [
        _vm.isLoading ? [_vm._m(0), _vm._m(1)] : _vm._e(),
        !_vm.isLoading && !_vm.isError
          ? [
              _c("PlanSummary", {
                staticClass: "mb-3",
                attrs: {
                  "plan-limits": _vm.planLimits,
                  "plan-obj": _vm.planObj,
                },
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card mb-3" }, [
      _c("div", { staticClass: "card-header pb-3 border-bottom" }, [
        _c("h4", { staticClass: "skeleton skeleton-title card-title mb-0" }),
      ]),
      _c("div", { staticClass: "card-body" }, [
        _c("p", { staticClass: "skeleton skeleton-text" }),
        _c("p", { staticClass: "skeleton skeleton-text" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header pb-3 border-bottom" }, [
        _c("h4", { staticClass: "skeleton skeleton-title card-title mb-0" }),
      ]),
      _c("div", { staticClass: "card-body" }, [
        _c("p", { staticClass: "skeleton skeleton-text" }),
        _c("p", { staticClass: "skeleton skeleton-text" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }