var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row position-relative" },
    [
      _vm.isLoading
        ? _c("loading-square", {
            staticClass: "mt-5",
            attrs: { overlay: true, message: "Loading..." },
          })
        : _vm._e(),
      _vm.form && _vm.form.length
        ? _c(
            "form",
            {
              staticClass: "col-12 col-md-10 card mx-auto",
              attrs: {
                role: "form",
                novalidate: "",
                name: "reportBuilderForm",
              },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubmitForm.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c(
                    "h4",
                    { staticClass: "text-capitalized" },
                    [
                      _vm._v(" " + _vm._s(_vm.formTitle) + " "),
                      _vm.reportInfo
                        ? _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: _vm.reportInfo,
                                "open-delay": 500,
                              },
                            },
                            [
                              _c("info-icon", {
                                staticClass: "cursor-pointer text-muted",
                                attrs: { title: "" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._l(_vm.form, function (input, index) {
                    return [
                      _c(
                        "div",
                        { key: index },
                        [
                          input.type === "text" ||
                          input.type === "textArea" ||
                          input.type === "email"
                            ? _c(
                                "base-input",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.createValidationObject(
                                        input,
                                        _vm.hasStorageCredentials
                                      ),
                                      expression:
                                        "createValidationObject(input, hasStorageCredentials)",
                                    },
                                  ],
                                  attrs: {
                                    "input-id":
                                      input.type === "textArea"
                                        ? `${index}`
                                        : null,
                                    error: _vm.errors.first(input.name),
                                    type:
                                      input.type === "textArea"
                                        ? "textarea"
                                        : "email"
                                        ? "email"
                                        : "text",
                                    required: input.required,
                                    label: input.label,
                                    name: input.name,
                                    placeholder:
                                      input.placeholder || "Enter text here",
                                  },
                                  model: {
                                    value: input.value,
                                    callback: function ($$v) {
                                      _vm.$set(input, "value", $$v)
                                    },
                                    expression: "input.value",
                                  },
                                },
                                [
                                  input.type === "textArea"
                                    ? _c("el-input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: _vm.createValidationObject(
                                              input,
                                              _vm.hasStorageCredentials
                                            ),
                                            expression:
                                              "createValidationObject(input, hasStorageCredentials)",
                                          },
                                        ],
                                        attrs: {
                                          id: `${input.label}--${index}`,
                                          type: "textarea",
                                          name: input.name,
                                          error: _vm.errors.first(input.name),
                                          required: input.required,
                                          rows: "4",
                                          autocomplete: "off",
                                          placeholder:
                                            input.placeholder ||
                                            "Enter text here",
                                        },
                                        model: {
                                          value: input.value,
                                          callback: function ($$v) {
                                            _vm.$set(input, "value", $$v)
                                          },
                                          expression: "input.value",
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "template",
                                    { slot: "helperText" },
                                    [
                                      input.tooltip &&
                                      input.name === "exportReportName" &&
                                      _vm.hasStorageCredentials
                                        ? _c(
                                            "el-alert",
                                            {
                                              staticClass: "mt-1",
                                              attrs: {
                                                type: "info",
                                                closable: false,
                                                "show-icon": "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(input.tooltip) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : input.instruction
                                        ? _c(
                                            "small",
                                            {
                                              staticClass:
                                                "form-text text-muted",
                                              attrs: { slot: "helperText" },
                                              slot: "helperText",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(input.instruction) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          input.type === "multipleEmail"
                            ? _c(
                                "multiple-email",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.createValidationObject(input),
                                      expression:
                                        "createValidationObject(input)",
                                    },
                                  ],
                                  attrs: {
                                    error: _vm.errors.first(input.name),
                                    required: input.required,
                                    label: input.label,
                                    name: input.name,
                                    placeholder: input.placeholder,
                                  },
                                  model: {
                                    value: input.value,
                                    callback: function ($$v) {
                                      _vm.$set(input, "value", $$v)
                                    },
                                    expression: "input.value",
                                  },
                                },
                                [
                                  input.instruction
                                    ? _c(
                                        "small",
                                        {
                                          staticClass: "form-text text-muted",
                                          attrs: { slot: "helperText" },
                                          slot: "helperText",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(input.instruction) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          input.type === "select"
                            ? _c(
                                "base-input",
                                {
                                  class: {
                                    "mb-2":
                                      input.optional && input.optional.length,
                                  },
                                  attrs: {
                                    "input-id": `${index}`,
                                    error: _vm.errors.first(input.name),
                                    required: input.required,
                                    label: input.label,
                                    name: input.name,
                                    placeholder: input.placeholder,
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value:
                                            _vm.createValidationObject(input),
                                          expression:
                                            "createValidationObject(input)",
                                        },
                                      ],
                                      staticClass: "select-success",
                                      attrs: {
                                        id: `${input.label}--${index}`,
                                        multiple: input.multiple,
                                        placeholder:
                                          input.placeholder || "Select",
                                        error: _vm.errors.first(input.name),
                                        name: input.name,
                                      },
                                      model: {
                                        value: input.value,
                                        callback: function ($$v) {
                                          _vm.$set(input, "value", $$v)
                                        },
                                        expression: "input.value",
                                      },
                                    },
                                    _vm._l(
                                      input.options,
                                      function (option, optionIndex) {
                                        return _c("el-option", {
                                          key: optionIndex,
                                          attrs: {
                                            value: option,
                                            label: option.name,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                  input.instruction
                                    ? _c(
                                        "small",
                                        {
                                          staticClass: "form-text text-muted",
                                          attrs: { slot: "helperText" },
                                          slot: "helperText",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(input.instruction) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          input.type === "dateRangeSelect"
                            ? _c(
                                "reports-date-picker",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.createValidationObject(input),
                                      expression:
                                        "createValidationObject(input)",
                                    },
                                  ],
                                  class: {
                                    "is-invalid": _vm.errors.has(input.name),
                                  },
                                  attrs: {
                                    error: _vm.errors.first(input.name),
                                    required: input.required,
                                    label: input.label,
                                    name: input.name,
                                    options: input.options,
                                    instruction: input.instruction,
                                  },
                                  model: {
                                    value: input.value,
                                    callback: function ($$v) {
                                      _vm.$set(input, "value", $$v)
                                    },
                                    expression: "input.value",
                                  },
                                },
                                [
                                  input.instruction
                                    ? _c(
                                        "small",
                                        {
                                          staticClass: "form-text text-muted",
                                          attrs: { slot: "helpText" },
                                          slot: "helpText",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(input.instruction) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          input.type === "radio"
                            ? _c(
                                "radio-group",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.createValidationObject(input),
                                      expression:
                                        "createValidationObject(input)",
                                    },
                                  ],
                                  class: {
                                    "mb-2":
                                      input.optional && input.optional.length,
                                  },
                                  attrs: {
                                    error: _vm.errors.first(input.name),
                                    required: input.required,
                                    label: input.label,
                                    name: input.name,
                                    options: input.options,
                                    tooltip: input.tooltip,
                                  },
                                  model: {
                                    value: input.value,
                                    callback: function ($$v) {
                                      _vm.$set(input, "value", $$v)
                                    },
                                    expression: "input.value",
                                  },
                                },
                                [
                                  input.instruction
                                    ? _c(
                                        "small",
                                        {
                                          staticClass: "form-text text-muted",
                                          attrs: { slot: "helperText" },
                                          slot: "helperText",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(input.instruction) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          input.type === "checkbox"
                            ? _c(
                                "checkbox-group",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.createValidationObject(input),
                                      expression:
                                        "createValidationObject(input)",
                                    },
                                  ],
                                  attrs: {
                                    error: _vm.errors.first(input.name),
                                    required: input.required,
                                    label: input.label,
                                    name: input.name,
                                    options: input.options,
                                  },
                                  model: {
                                    value: input.value,
                                    callback: function ($$v) {
                                      _vm.$set(input, "value", $$v)
                                    },
                                    expression: "input.value",
                                  },
                                },
                                [
                                  input.instruction
                                    ? _c(
                                        "small",
                                        {
                                          staticClass: "form-text text-muted",
                                          attrs: { slot: "helperText" },
                                          slot: "helperText",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(input.instruction) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          input.type === "productSelect"
                            ? _c(
                                "product-select",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.createValidationObject(input),
                                      expression:
                                        "createValidationObject(input)",
                                    },
                                  ],
                                  attrs: {
                                    error: _vm.errors.first(input.name),
                                    bfilters: input.bfilters,
                                    required: input.required,
                                    label: input.label,
                                    name: input.name,
                                    service: input.service,
                                  },
                                  model: {
                                    value: input.value,
                                    callback: function ($$v) {
                                      _vm.$set(input, "value", $$v)
                                    },
                                    expression: "input.value",
                                  },
                                },
                                [
                                  input.instruction
                                    ? _c(
                                        "small",
                                        {
                                          staticClass: "form-text text-muted",
                                          attrs: { slot: "helperText" },
                                          slot: "helperText",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(input.instruction) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          input.type === "searchTermsSelect"
                            ? _c(
                                "search-terms-select",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.createValidationObject(input),
                                      expression:
                                        "createValidationObject(input)",
                                    },
                                  ],
                                  attrs: {
                                    error: _vm.errors.first(input.name),
                                    bfilters: input.bfilters,
                                    required: input.required,
                                    label: input.label,
                                    name: input.name,
                                    service: input.service,
                                    "max-select": input.max_select,
                                    "is-manual": input.is_manual,
                                  },
                                  model: {
                                    value: input.value,
                                    callback: function ($$v) {
                                      _vm.$set(input, "value", $$v)
                                    },
                                    expression: "input.value",
                                  },
                                },
                                [
                                  input.instruction
                                    ? _c(
                                        "small",
                                        {
                                          staticClass: "form-text text-muted",
                                          attrs: { slot: "helperText" },
                                          slot: "helperText",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(input.instruction) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          input.type === "customFieldSelect"
                            ? [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "custom-field-select",
                                      {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value:
                                              _vm.createValidationObject(input),
                                            expression:
                                              "createValidationObject(input)",
                                          },
                                        ],
                                        attrs: {
                                          error: _vm.errors.first(input.name),
                                          required: input.required,
                                          "is-simple": input.is_simple,
                                          label: input.label,
                                          name: input.name,
                                          placeholder: input.placeholder,
                                          service: input.service,
                                        },
                                        model: {
                                          value: input.value,
                                          callback: function ($$v) {
                                            _vm.$set(input, "value", $$v)
                                          },
                                          expression: "input.value",
                                        },
                                      },
                                      [
                                        input.instruction
                                          ? _c(
                                              "small",
                                              {
                                                staticClass:
                                                  "form-text text-muted",
                                                attrs: { slot: "helperText" },
                                                slot: "helperText",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(input.instruction) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          input.type === "cloudstorage" &&
                          _vm.hasStorageCredentials
                            ? _c(
                                "cloud-storage-select",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.createValidationObject(input),
                                      expression:
                                        "createValidationObject(input)",
                                    },
                                  ],
                                  attrs: {
                                    error: _vm.errors.first(input.name),
                                    placeholder: input.placeholder,
                                    label: input.label,
                                    name: input.name,
                                  },
                                  model: {
                                    value: input.value,
                                    callback: function ($$v) {
                                      _vm.$set(input, "value", $$v)
                                    },
                                    expression: "input.value",
                                  },
                                },
                                [
                                  input.instruction
                                    ? _c(
                                        "small",
                                        {
                                          staticClass: "form-text text-muted",
                                          attrs: { slot: "helperText" },
                                          slot: "helperText",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(input.instruction) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          input.type === "brandSelect"
                            ? _c(
                                "brand-select",
                                {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.createValidationObject(input),
                                      expression:
                                        "createValidationObject(input)",
                                    },
                                  ],
                                  attrs: {
                                    error: _vm.errors.first(input.name),
                                    required: input.required,
                                    label: input.label,
                                    name: input.name,
                                    placeholder: input.placeholder,
                                  },
                                  model: {
                                    value: input.value,
                                    callback: function ($$v) {
                                      _vm.$set(input, "value", $$v)
                                    },
                                    expression: "input.value",
                                  },
                                },
                                [
                                  input.instruction
                                    ? _c(
                                        "small",
                                        {
                                          staticClass: "form-text text-muted",
                                          attrs: { slot: "helperText" },
                                          slot: "helperText",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(input.instruction) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                      input.optional
                        ? [
                            _vm._l(
                              input.optional,
                              function (optionalInput, optionalIndex) {
                                return [
                                  _vm.isOptionalInput(
                                    optionalInput.optional_values,
                                    input.value
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          key: `${input.name}-${optionalInput.name}-${optionalIndex}`,
                                        },
                                        [
                                          optionalInput.type === "text" ||
                                          optionalInput.type === "textArea" ||
                                          optionalInput.type === "email"
                                            ? _c(
                                                "base-input",
                                                {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value:
                                                        _vm.createValidationObject(
                                                          optionalInput
                                                        ),
                                                      expression:
                                                        "createValidationObject(optionalInput)",
                                                    },
                                                  ],
                                                  class: {
                                                    "mb-2":
                                                      optionalIndex !==
                                                      input.optional.length - 1,
                                                  },
                                                  attrs: {
                                                    "input-id": `${optionalIndex}`,
                                                    error: _vm.errors.first(
                                                      optionalInput.name
                                                    ),
                                                    type:
                                                      optionalInput.type ===
                                                      "textArea"
                                                        ? "textarea"
                                                        : "email"
                                                        ? "email"
                                                        : "text",
                                                    required:
                                                      optionalInput.required,
                                                    label: optionalInput.label,
                                                    name: optionalInput.name,
                                                    placeholder:
                                                      optionalInput.placeholder,
                                                  },
                                                  model: {
                                                    value: optionalInput.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        optionalInput,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "optionalInput.value",
                                                  },
                                                },
                                                [
                                                  optionalInput.type ===
                                                  "textArea"
                                                    ? _c("el-input", {
                                                        directives: [
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate",
                                                            value:
                                                              _vm.createValidationObject(
                                                                optionalInput
                                                              ),
                                                            expression:
                                                              "createValidationObject(optionalInput)",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          id: `${optionalInput.label}--${optionalIndex}`,
                                                          type: "text-area",
                                                          error:
                                                            _vm.errors.first(
                                                              optionalInput.name
                                                            ),
                                                          required:
                                                            optionalInput.required,
                                                          rows: "3",
                                                          autocomplete: "off",
                                                        },
                                                        model: {
                                                          value:
                                                            optionalInput.value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              optionalInput,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "optionalInput.value",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  optionalInput.instruction
                                                    ? _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "form-text text-muted",
                                                          attrs: {
                                                            slot: "helperText",
                                                          },
                                                          slot: "helperText",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                optionalInput.instruction
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          optionalInput.type === "multipleEmail"
                                            ? _c(
                                                "multiple-email",
                                                {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value:
                                                        _vm.createValidationObject(
                                                          optionalInput
                                                        ),
                                                      expression:
                                                        "createValidationObject(optionalInput)",
                                                    },
                                                  ],
                                                  class: {
                                                    "mb-2":
                                                      optionalIndex !==
                                                      input.optional.length - 1,
                                                  },
                                                  attrs: {
                                                    error: _vm.errors.first(
                                                      optionalInput.name
                                                    ),
                                                    required:
                                                      optionalInput.required,
                                                    label: optionalInput.label,
                                                    name: optionalInput.name,
                                                    placeholder:
                                                      optionalInput.placeholder,
                                                  },
                                                  model: {
                                                    value: optionalInput.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        optionalInput,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "optionalInput.value",
                                                  },
                                                },
                                                [
                                                  optionalInput.instruction
                                                    ? _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "form-text text-muted",
                                                          attrs: {
                                                            slot: "helperText",
                                                          },
                                                          slot: "helperText",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                optionalInput.instruction
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                          optionalInput.type === "select"
                                            ? _c(
                                                "base-input",
                                                {
                                                  class: {
                                                    "mb-2":
                                                      optionalIndex !==
                                                      input.optional.length - 1,
                                                  },
                                                  attrs: {
                                                    "input-id": `${optionalIndex}`,
                                                    error: _vm.errors.first(
                                                      optionalInput.name
                                                    ),
                                                    required:
                                                      optionalInput.required,
                                                    label: optionalInput.label,
                                                    name: optionalInput.name,
                                                    placeholder:
                                                      optionalInput.placeholder,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "validate",
                                                          rawName: "v-validate",
                                                          value:
                                                            _vm.createValidationObject(
                                                              optionalInput
                                                            ),
                                                          expression:
                                                            "createValidationObject(optionalInput)",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "select-success",
                                                      attrs: {
                                                        id: `${optionalInput.label}--${optionalIndex}`,
                                                        multiple:
                                                          optionalInput.multiple,
                                                        placeholder:
                                                          optionalInput.placeholder ||
                                                          "Select",
                                                        error: _vm.errors.first(
                                                          optionalInput.name
                                                        ),
                                                        name: optionalInput.name,
                                                      },
                                                      model: {
                                                        value:
                                                          optionalInput.value,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            optionalInput,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "optionalInput.value",
                                                      },
                                                    },
                                                    _vm._l(
                                                      optionalInput.options,
                                                      function (
                                                        option,
                                                        optionIndex
                                                      ) {
                                                        return _c("el-option", {
                                                          key: optionIndex,
                                                          attrs: {
                                                            value: option,
                                                            label: option.name,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                  optionalInput.instruction
                                                    ? _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "form-text text-muted",
                                                          attrs: {
                                                            slot: "helperText",
                                                          },
                                                          slot: "helperText",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                optionalInput.instruction
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          optionalInput.type ===
                                          "dateRangeSelect"
                                            ? _c(
                                                "reports-date-picker",
                                                {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value:
                                                        _vm.createValidationObject(
                                                          optionalInput
                                                        ),
                                                      expression:
                                                        "createValidationObject(optionalInput)",
                                                    },
                                                  ],
                                                  class: {
                                                    "mb-2":
                                                      optionalIndex !==
                                                      input.optional.length - 1,
                                                  },
                                                  attrs: {
                                                    error: _vm.errors.first(
                                                      optionalInput.name
                                                    ),
                                                    required:
                                                      optionalInput.required,
                                                    label: optionalInput.label,
                                                    name: optionalInput.name,
                                                    options:
                                                      optionalInput.options,
                                                    instruction:
                                                      optionalInput.instruction,
                                                  },
                                                  model: {
                                                    value: optionalInput.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        optionalInput,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "optionalInput.value",
                                                  },
                                                },
                                                [
                                                  optionalInput.instruction
                                                    ? _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "form-text text-muted",
                                                          attrs: {
                                                            slot: "helpText",
                                                          },
                                                          slot: "helpText",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                optionalInput.instruction
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                          optionalInput.type === "radio"
                                            ? _c(
                                                "radio-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value:
                                                        _vm.createValidationObject(
                                                          optionalInput
                                                        ),
                                                      expression:
                                                        "createValidationObject(optionalInput)",
                                                    },
                                                  ],
                                                  class: {
                                                    "mb-2":
                                                      optionalIndex !==
                                                      input.optional.length - 1,
                                                  },
                                                  attrs: {
                                                    error: _vm.errors.first(
                                                      optionalInput.name
                                                    ),
                                                    required:
                                                      optionalInput.required,
                                                    label: optionalInput.label,
                                                    name: optionalInput.name,
                                                    options:
                                                      optionalInput.options,
                                                  },
                                                  model: {
                                                    value: optionalInput.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        optionalInput,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "optionalInput.value",
                                                  },
                                                },
                                                [
                                                  optionalInput.instruction
                                                    ? _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "form-text text-muted",
                                                          attrs: {
                                                            slot: "helperText",
                                                          },
                                                          slot: "helperText",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                optionalInput.instruction
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                          optionalInput.type === "checkbox"
                                            ? _c(
                                                "checkbox-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value:
                                                        _vm.createValidationObject(
                                                          optionalInput
                                                        ),
                                                      expression:
                                                        "createValidationObject(optionalInput)",
                                                    },
                                                  ],
                                                  class: {
                                                    "mb-2":
                                                      optionalIndex !==
                                                      input.optional.length - 1,
                                                  },
                                                  attrs: {
                                                    error: _vm.errors.first(
                                                      optionalInput.name
                                                    ),
                                                    required:
                                                      optionalInput.required,
                                                    label: optionalInput.label,
                                                    name: optionalInput.name,
                                                    options:
                                                      optionalInput.options,
                                                  },
                                                  model: {
                                                    value: optionalInput.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        optionalInput,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "optionalInput.value",
                                                  },
                                                },
                                                [
                                                  optionalInput.instruction
                                                    ? _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "form-text text-muted",
                                                          attrs: {
                                                            slot: "helperText",
                                                          },
                                                          slot: "helperText",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                optionalInput.instruction
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                          optionalInput.type === "productSelect"
                                            ? _c(
                                                "product-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value:
                                                        _vm.createValidationObject(
                                                          optionalInput
                                                        ),
                                                      expression:
                                                        "createValidationObject(optionalInput)",
                                                    },
                                                  ],
                                                  class: {
                                                    "mb-2":
                                                      optionalIndex !==
                                                      input.optional.length - 1,
                                                  },
                                                  attrs: {
                                                    error: _vm.errors.first(
                                                      optionalInput.name
                                                    ),
                                                    bfilters:
                                                      optionalInput.bfilters,
                                                    required:
                                                      optionalInput.required,
                                                    label: optionalInput.label,
                                                    name: optionalInput.name,
                                                    service:
                                                      optionalInput.service,
                                                  },
                                                  model: {
                                                    value: optionalInput.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        optionalInput,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "optionalInput.value",
                                                  },
                                                },
                                                [
                                                  optionalInput.instruction
                                                    ? _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "form-text text-muted",
                                                          attrs: {
                                                            slot: "helperText",
                                                          },
                                                          slot: "helperText",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                optionalInput.instruction
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                          optionalInput.type ===
                                          "searchTermsSelect"
                                            ? _c(
                                                "search-terms-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value:
                                                        _vm.createValidationObject(
                                                          optionalInput
                                                        ),
                                                      expression:
                                                        "createValidationObject(optionalInput)",
                                                    },
                                                  ],
                                                  class: {
                                                    "mb-2":
                                                      optionalIndex !==
                                                      input.optional.length - 1,
                                                  },
                                                  attrs: {
                                                    error: _vm.errors.first(
                                                      optionalInput.name
                                                    ),
                                                    bfilters:
                                                      optionalInput.bfilters,
                                                    required:
                                                      optionalInput.required,
                                                    label: optionalInput.label,
                                                    name: optionalInput.name,
                                                    service:
                                                      optionalInput.service,
                                                    "max-select":
                                                      optionalInput.max_select,
                                                    "is-manual":
                                                      optionalInput.is_manual,
                                                  },
                                                  model: {
                                                    value: optionalInput.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        optionalInput,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "optionalInput.value",
                                                  },
                                                },
                                                [
                                                  optionalInput.instruction
                                                    ? _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "form-text text-muted",
                                                          attrs: {
                                                            slot: "helperText",
                                                          },
                                                          slot: "helperText",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                optionalInput.instruction
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                          optionalInput.type ===
                                          "customFieldSelect"
                                            ? [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "custom-field-select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate",
                                                            value:
                                                              _vm.createValidationObject(
                                                                optionalInput
                                                              ),
                                                            expression:
                                                              "createValidationObject(optionalInput)",
                                                          },
                                                        ],
                                                        class: {
                                                          "mb-2":
                                                            optionalIndex !==
                                                            input.optional
                                                              .length -
                                                              1,
                                                        },
                                                        attrs: {
                                                          error:
                                                            _vm.errors.first(
                                                              optionalInput.name
                                                            ),
                                                          required:
                                                            optionalInput.required,
                                                          "is-simple":
                                                            optionalInput.is_simple,
                                                          label:
                                                            optionalInput.label,
                                                          name: optionalInput.name,
                                                          placeholder:
                                                            optionalInput.placeholder,
                                                          service:
                                                            optionalInput.service,
                                                        },
                                                        model: {
                                                          value:
                                                            optionalInput.value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              optionalInput,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "optionalInput.value",
                                                        },
                                                      },
                                                      [
                                                        optionalInput.instruction
                                                          ? _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "form-text text-muted",
                                                                attrs: {
                                                                  slot: "helperText",
                                                                },
                                                                slot: "helperText",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      optionalInput.instruction
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                          optionalInput.type ===
                                            "cloudstorage" &&
                                          _vm.hasStorageCredentials
                                            ? _c(
                                                "cloud-storage-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value:
                                                        _vm.createValidationObject(
                                                          optionalInput
                                                        ),
                                                      expression:
                                                        "createValidationObject(optionalInput)",
                                                    },
                                                  ],
                                                  class: {
                                                    "mb-2":
                                                      optionalIndex !==
                                                      input.optional.length - 1,
                                                  },
                                                  attrs: {
                                                    error: _vm.errors.first(
                                                      optionalInput.name
                                                    ),
                                                    placeholder:
                                                      optionalInput.placeholder,
                                                    label: optionalInput.label,
                                                    name: optionalInput.name,
                                                  },
                                                  model: {
                                                    value: optionalInput.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        optionalInput,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "optionalInput.value",
                                                  },
                                                },
                                                [
                                                  optionalInput.instruction
                                                    ? _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "form-text text-muted",
                                                          attrs: {
                                                            slot: "helperText",
                                                          },
                                                          slot: "helperText",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                optionalInput.instruction
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                          optionalInput.type === "brandSelect"
                                            ? _c(
                                                "brand-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value:
                                                        _vm.createValidationObject(
                                                          optionalInput
                                                        ),
                                                      expression:
                                                        "createValidationObject(optionalInput)",
                                                    },
                                                  ],
                                                  class: {
                                                    "mb-2":
                                                      optionalIndex !==
                                                      input.optional.length - 1,
                                                  },
                                                  attrs: {
                                                    error: _vm.errors.first(
                                                      optionalInput.name
                                                    ),
                                                    required:
                                                      optionalInput.required,
                                                    label: optionalInput.label,
                                                    name: optionalInput.name,
                                                    placeholder:
                                                      optionalInput.placeholder,
                                                  },
                                                  model: {
                                                    value: optionalInput.value,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        optionalInput,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "optionalInput.value",
                                                  },
                                                },
                                                [
                                                  optionalInput.instruction
                                                    ? _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "form-text text-muted",
                                                          attrs: {
                                                            slot: "helperText",
                                                          },
                                                          slot: "helperText",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                optionalInput.instruction
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _c("div", {
                                        key: `${input.name}-${optionalInput.name}-${optionalIndex}`,
                                        class: {
                                          "mb-3":
                                            optionalIndex ===
                                            input.optional.length - 1,
                                        },
                                      }),
                                ]
                              }
                            ),
                          ]
                        : _vm._e(),
                    ]
                  }),
                  _vm.formErrorMessage
                    ? _c(
                        "el-alert",
                        {
                          attrs: {
                            type: "error",
                            "show-icon": "",
                            title: _vm.formErrorMessage.title,
                            closable: false,
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.formErrorMessage.message) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            "native-type": "button",
                            plain: "",
                          },
                          on: { click: _vm.onCancelForm },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "el-button",
                        { attrs: { type: "primary", "native-type": "submit" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isEdit
                                  ? `Update ${_vm.reportLabel()}`
                                  : `Create ${_vm.reportLabel()}`
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            top: "65px",
            title: `${
              _vm.isEdit ? "Update" : "Save"
            } and Download ${_vm.reportLabel(true)}`,
            visible: _vm.$showSaveReportModal,
            "destroy-on-close": "",
            width: "50%",
            "custom-class": "dialog-secondary",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            close: function ($event) {
              _vm.showSaveReportModal = false
            },
          },
        },
        [
          _c("p", [
            _vm._v(
              " You are creating a non-recurring " +
                _vm._s(_vm.reportLabel()) +
                ". Would you like to save it? "
            ),
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "plain", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.processDownloadReport(_vm.showSaveReportModal)
                    },
                  },
                },
                [_vm._v(" No, just download it ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.processSaveReport(_vm.showSaveReportModal)
                    },
                  },
                },
                [
                  _vm._v(
                    " Yes, " +
                      _vm._s(_vm.isEdit ? "update" : "save") +
                      " and download "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }