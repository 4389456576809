var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "65px",
        title: `Share Report: ${_vm.title}`,
        visible: _vm.show,
        "destroy-on-close": "",
        width: "75%",
        "custom-class": "dialog-primary share-report-modal",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: { close: () => _vm.$emit("on-cancel") },
    },
    [
      _c(
        "div",
        { staticClass: "container-fluid px-0" },
        [
          _vm.isLoading
            ? _c("div", { staticClass: "row no-gutters" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("div", { staticClass: "mb-2 text-center" }, [
                      _vm._v(" Loading Link "),
                    ]),
                    _c("loading-square"),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          !_vm.isLoading
            ? _c("div", { staticClass: "row pb-3 mb-3 border-bottom" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("label", { attrs: { for: "share-report-link" } }, [
                      _vm._v(" Share link "),
                    ]),
                    _c(
                      "el-input",
                      {
                        attrs: {
                          id: "share-report-link",
                          value: _vm.sharedLink,
                          readonly: "",
                        },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              slot: "append",
                              content: "Click to copy link",
                            },
                            slot: "append",
                          },
                          [
                            _c("el-button", {
                              attrs: { icon: "el-icon-copy-document" },
                              on: { click: _vm.onCopyLink },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.expirationDays > 0
                      ? _c(
                          "div",
                          { staticClass: "text-muted form-text small" },
                          [
                            _vm._v(
                              " This share link will expire in " +
                                _vm._s(_vm.expirationDays) +
                                " days. "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          !_vm.isLoading && _vm.sharedAccounts.length > 0 && !_vm.errorMessage
            ? _c(
                "div",
                { staticClass: "row no-gutters" },
                [
                  _vm._l(_vm.sharedAccounts, function (account, index) {
                    return _c(
                      "form",
                      {
                        key: `shared-account--${index}`,
                        staticClass: "col-12",
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return (() =>
                              _vm.onSubmitForm(account, index)).apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "row no-gutters" }, [
                          _c(
                            "div",
                            { staticClass: "col-12 col-lg-4 mr-2" },
                            [
                              _c("base-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: _vm.modelValidations.email,
                                    expression: "modelValidations.email",
                                  },
                                ],
                                attrs: {
                                  "data-vv-scope": "email" + index,
                                  name: "email",
                                  type: "email",
                                  label: "Email",
                                  placeholder: "Enter email",
                                  error: _vm.errors.first(
                                    `email${index}.email`
                                  ),
                                  required: true,
                                  disabled:
                                    _vm.isLoadingSubmit || account.isAdded,
                                },
                                model: {
                                  value: account.email,
                                  callback: function ($$v) {
                                    _vm.$set(account, "email", $$v)
                                  },
                                  expression: "account.email",
                                },
                              }),
                            ],
                            1
                          ),
                          !account.isAdded
                            ? _c(
                                "div",
                                { staticClass: "col-12 col-lg-4 mr-2" },
                                [
                                  _c("base-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: _vm.modelValidations.password,
                                        expression: "modelValidations.password",
                                      },
                                    ],
                                    attrs: {
                                      "data-vv-scope": "password" + index,
                                      name: "password",
                                      type: "password",
                                      label: "Password",
                                      placeholder: "Password",
                                      error: _vm.errors.first(
                                        `password${index}.password`
                                      ),
                                      required: true,
                                      disabled: _vm.isLoadingSubmit,
                                    },
                                    model: {
                                      value: account.password,
                                      callback: function ($$v) {
                                        _vm.$set(account, "password", $$v)
                                      },
                                      expression: "account.password",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "col-12 col-lg-3" },
                            [
                              !account.isAdded
                                ? _c(
                                    "el-button",
                                    {
                                      class: {
                                        disabled:
                                          _vm.isLoadingSubmit ||
                                          _vm.errors.any(),
                                      },
                                      staticStyle: {
                                        "margin-top": "28px",
                                        height: "35px",
                                      },
                                      attrs: {
                                        type: "success",
                                        "native-type": "submit",
                                        size: "small",
                                        disabled:
                                          _vm.isLoadingSubmit ||
                                          _vm.errors.any(),
                                        loading: _vm.isLoadingSubmit,
                                      },
                                    },
                                    [_vm._v(" Save email ")]
                                  )
                                : _vm._e(),
                              account.isAdded
                                ? _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content:
                                          "Deleting an email will permanently remove its access to the shared report. You can always re-add an email at anytime.",
                                        "open-delay": 500,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          class: {
                                            disabled: _vm.isLoadingSubmit,
                                          },
                                          staticStyle: {
                                            "margin-top": "28px",
                                            height: "35px",
                                          },
                                          attrs: {
                                            type: "danger",
                                            "native-type": "button",
                                            size: "small",
                                            disabled: _vm.isLoadingSubmit,
                                            loading: _vm.isLoadingSubmit,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onDeleteAccount(
                                                account,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Remove email ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("el-alert", {
                        attrs: {
                          type: "info",
                          description:
                            "An email and password must be provided to view a shared report.",
                          "show-icon": "",
                          closable: false,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          !_vm.isLoading && _vm.sharedAccounts.length === 0 && !_vm.errorMessage
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 text-center" },
                  [
                    _c("p", [
                      _vm._v(" You are not sharing this report with anyone. "),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.onAddAccount()
                          },
                        },
                      },
                      [_vm._v(" Add an email ")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.errorMessage
            ? _c(
                "el-alert",
                { attrs: { type: "error", "show-icon": "", closable: false } },
                [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "footer" },
        [
          _vm.expirationDays < 1
            ? _c(
                "el-button",
                {
                  attrs: { plain: "", loading: _vm.isLoadingSubmit },
                  on: { click: _vm.onRefreshSharedReportExpirationTimestamp },
                },
                [_vm._v(" Refresh expiration ")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: {
                type: "danger",
                plain: "",
                disabled: _vm.isLoadingSubmit,
              },
              on: { click: () => _vm.$emit("on-cancel") },
            },
            [_vm._v(" Cancel ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }