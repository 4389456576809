var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "card-body" },
      [
        _vm._l(_vm.planData.plans, function (plan) {
          return _c(
            "div",
            { key: plan.id, staticClass: "mb-2" },
            [
              _vm.planData.currentPlanId === plan.id
                ? [
                    _c("strong", [_vm._v("Current Plan: ")]),
                    _c("span", [_vm._v(_vm._s(plan.name))]),
                  ]
                : _vm._e(),
            ],
            2
          )
        }),
        _vm._l(_vm.planLimits, function (value, key, index) {
          return _c("div", { key: index, staticClass: "mb-2" }, [
            _c("strong", [_vm._v(_vm._s(value.name) + ": ")]),
            _c("span", [
              _vm._v(
                " Up to " +
                  _vm._s(_vm._f("toString")(value.maxCount)) +
                  " " +
                  _vm._s(key !== "runits" ? "Listings" : "Reports") +
                  " "
              ),
            ]),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header border-bottom" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v(" Plan Summary ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }