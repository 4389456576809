var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("base-grid", {
        ref: _vm.gridRef,
        staticClass: "grid-sellers",
        attrs: {
          service: _vm.service,
          "edit-header": true,
          "is-grid-loading": _vm.isGridLoading(_vm.service),
          "is-date-loading": _vm.isDateLoading,
          "grid-state": _vm.gridState(_vm.service),
          "download-options": _vm.downloadOptions(_vm.service),
          "enable-filter-actions": true,
          "enable-delete-rows": false,
          "enable-row-selection": false,
          "enable-checkbox": false,
          "suppress-row-click-selection": true,
          "column-cell-renderers": _vm.columnCellRenderers(_vm.service),
          "column-cell-editors": _vm.columnCellEditors(_vm.service),
          "detail-grid-service": "detailedpricebox",
          "detail-row-data": _vm.getDetailRowData,
          "enable-date-range": true,
        },
        on: {
          "set-grid-state": (payload) =>
            _vm.setGridState({ service: _vm.service, ...payload }),
          "grid-date-range-changed": _vm.onGridDateRangeChanged,
          "select-download": _vm.onSelectDownload,
          "cell-value-changed": _vm.onCellValueChanged,
          "column-changed": (obj) => _vm.onColumnChanged("Sellers", obj),
          "save-grid-filters": _vm.onSaveGridFilters,
          "delete-grid-filters": _vm.onDeleteGridFilters,
          "update-header-text": _vm.onUpdateHeaderText,
        },
      }),
      _c("save-filters-modal", {
        attrs: {
          show: _vm.showSaveFiltersModal.show,
          "is-save-filter": _vm.showSaveFiltersModal.isSaveFilter,
          filters: _vm.showSaveFiltersModal.filters,
          "grid-filter": _vm.showSaveFiltersModal.gridFilter,
          "grid-sort": _vm.showSaveFiltersModal.gridSort,
          "is-loading": _vm.isGridFilterLoading,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.showSaveFiltersModal, "show", $event)
          },
          onConfirmSaveGridFilter: (obj) =>
            _vm.onConfirmSaveGridFilter("Sellers", obj),
          onConfirmDeleteGridFilter: (obj) =>
            _vm.onConfirmDeleteGridFilter("Sellers", obj),
          onCancelSaveFilters: _vm.onCancelSaveFilters,
        },
      }),
      _c("grid-download-modal", {
        attrs: {
          show: _vm.gridDownloadModal.show,
          export: _vm.gridDownloadModal.export,
        },
        on: {
          close: _vm.onResetGridDownloadModal,
          submit: _vm.onSubmitGridDownloadModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }